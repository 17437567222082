.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.h-100 {
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.d-inline-flex {

}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/**
 * should be used when positioning non-text stuff. For all typography and text sizing/positioning values set by <Typography> should be used
 */
.lh-1 {
  line-height: 1;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.max-w-full {
  max-width: 100%;
}

.text-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-break {
  word-break: break-word;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

//.flex-wrap {
//  flex: wrap;
//}
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.abs-v-center {
  left: 50%;
  transform: translateX(-50%);
}

.abs-h-center {
  top: 50%;
  transform: translateY(-50%);
}

.border-md {
  border-width: 2px;
  border-style: solid;
}


.border-radius-xxs {
  border-radius: map-get($spacers, xxs);
}

.rounded {
  border-radius: 50%;
}

.flex-v-center {
  display: flex;
  align-items: center;
}

.flex-h-center {
  display: flex;
  justify-content: center;
}

.horizontal-divider {
  width: 100%;
  height: 2px;
}
