/*****************************************************************************************************************************/
/****** WARNING! THIS FILE IS AUTO-GENERATED. DO NOT EDIT IT MANUALLY OR OTHERWISE YOUR CHANGES WILL BE OVERWRITTEN!! ******/
/*****************************************************************************************************************************/

.HeaderBold {
    font-family: sans-serif;
    font-weight: 800;
}
    
.HeaderRegular {
    font-family: sans-serif;
    font-weight: 400;
}
    
.HeaderLight {
    font-family: sans-serif;
    font-weight: 300;
}
    
.BodyBold {
    font-family: sans-serif;
    font-weight: 800;
}
    
.BodyRegular {
    font-family: sans-serif;
    font-weight: 400;
}
    
.BodyLight {
    font-family: sans-serif;
    font-weight: 300;
}
    
body{
    font-family: undefined;
}
